import React from "react";
import "../CSS files/About.css";
import bookCover from "../images/avatar.webp";
import podcastCover from "../images/galavant.webp";
import showCover from "../images/PODCAST.webp";
import hobbyCover from "../images/francais.webp";
import myphoto from "../images/about-me.webp";
import { motion } from "framer-motion";

const About = () => {
  const favorites = [
    {
      type: "FAVOURITE SHOW",
      title: "Favourite Show",
      subtitle: "ATLA",
      image: bookCover,
    },
    {
      type: "FAVORITE PODCAST",
      title: "Favorite Podcast",
      subtitle: "Business Movers",
      image: showCover,
    },
    {
      type: "CURRENTLY LEARNING",
      title: "Currently Learning",
      subtitle: "Français",
      image: hobbyCover,
    },
    {
      type: "FAVORITE ACTIVITY",
      title: "Favorite Activity",
      subtitle: "Gallivanting or taking pictures",
      image: podcastCover,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
        duration: 1.5,
      },
    },
  };

  return (
    <motion.div
      className="about-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <section className="about-intro">
        <img src={myphoto} alt="Profile" className="drop-cap-image" />
        <p>
          Hi, I'm Adéọlá, a product designer based in Lagos, Nigeria. My name is
          of Yoruba origin and means "crown of wealth." While my main focus is
          design, I see myself as a creative at heart, shaping my approach to
          both life and work. I transitioned to product design three years ago
          after two impactful years in marketing, which deepened my
          understanding of user needs.
        </p>

        <p>
          Currently, I work as a product designer, focusing on designing
          innovative B2B products. My role involves advocating for users,
          conducting market research, designing, analyzing data, and making strategic
          design decisions. 
        </p>
      </section>

      <section className="skills-section">
        <h3>Skills</h3>
        <ul className="skills-grid">
          <li>UI/UX Design</li>
          <li>Research</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Animations</li>
          <li>Illustrations</li>
          <li>Design Systems</li>
          <li>Marketing Strategy</li>
          <li>Prototyping</li>
        </ul>
      </section>

      <section className="contact-moi">
        <h3>Contact</h3>
        <div className="contact-div">
          <a
            href="https://www.linkedin.com/in/adeolaadekoya/"
            rel="noreferrer noopener"
            target="_blank"
          >
            linkedin.com/in/adeolaadekoya
          </a>
          <p>LinkedIn</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://www.behance.net/adeolaadekoya"
            rel="noreferrer noopener"
            target="_blank"
          >
            behance.net/adeolaadekoya
          </a>
          <p>Behance</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://x.com/adeoladev"
            rel="noreferrer noopener"
            target="_blank"
          >
            @adeoladev
          </a>
          <p>Twitter</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://www.figma.com/@adeolaadekoya"
            rel="noreferrer noopener"
            target="_blank"
          >
            @adeolaadekoya
          </a>
          <p>Figma Community</p>
        </div>
      </section>

      <div className="favorites-section">
        <h2>Random Facts</h2>
        <div className="favorites-grid">
          {favorites.map((item, index) => (
            <div key={index} className="favorite-card">
              <div className="favorite-image-wrapper">
                <img
                  src={item.image}
                  alt={item.title}
                  className="favorite-image"
                />
              </div>
              <div className="favorite-content">
                <h3 className="favorite-title">{item.title}</h3>
                {item.subtitle && (
                  <p className="favorite-subtitle">{item.subtitle}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="spotify-embed">
        <h2>What I'm listening to</h2>
        <iframe
          src="https://open.spotify.com/embed/playlist/4FqZ9NEhxxENGtqPVhKjfj?utm_source=generator&theme=0"
          width="50%"
          height="460"
          title="Spotify Playlist"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </motion.div>
  );
};

export default About;
