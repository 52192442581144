import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import backgroundImage from "../images/Lights.png";
import pin from "../icons/map-pin.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../CSS files/Homepage.css";
import { Link } from "react-router-dom";
import projects from "../Projects";


gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const location = useLocation();
  const section4Ref = useRef(null);

  // Function to create squares grid (unchanged)

  const renderSquares = () => {
    const squares = [];
    const numberOfSquares = 40 * Math.ceil(window.innerHeight / 80);
    for (let i = 0; i < numberOfSquares; i++) {
      squares.push(<div key={i} className="square" />);
    }
    return squares;
  };

  // Scroll to section 4 when the hash in the URL changes
  useEffect(() => {
    const scrollToSection = () => {
      const hash = location.hash.replace("#", "");
      if (hash === "section-4" && section4Ref.current) {
        section4Ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToSection();

    // Listen for popstate events (back/forward navigation)
    window.addEventListener("popstate", scrollToSection);

    return () => {
      window.removeEventListener("popstate", scrollToSection);
    };
  }, [location]);

  return (
    <div>
      <div className="homepage">
        <div className="background-wrapper">
          <img
            src={backgroundImage}
            alt="Background"
            className="background-image"
          />
        </div>
        <div id="header-wrapper">
          <div id="main-text">
            <span className="header-text">Creative, solution-driven</span>
            <span className="header-text">product designer</span>
          </div>
          <p className="body-text-one light-text">Get to know me✨</p>
        </div>
        <div className="squares-grid">{renderSquares()}</div>
      </div>

      <section className="section-two">
        <motion.div
          className="center-aligned reveal-content"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.5 }}
        >
          <div className="location">
            <img src={pin} alt="contact arrow" className="pin-icon" />
            <span className="light-text">Lagos, NG</span>
          </div>
          <div className="about-me">
            <div id="section-one-flex">
              <span className="second-text light-text">
                Hey, i'm Adéọlá,
                <span className="dark-text"> nice to meet you.</span>
              </span>
            </div>
            <span className="second-text light-text dark-text">
              I am a product designer and creative who has spent the last few
              years collaborating with teams and helping founders create unique
              product experiences.
            </span>
          </div>
        </motion.div>
      </section>

      <section className="section-4" ref={section4Ref} id="section-4">
        <div className="project-boxes">
          {projects.map((project, index) => (
            <motion.div
              key={project.id}
              className="project"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <Link to={`/projects/${project.id}`}>
                <div className="image-container-two">
                  <img
                    className="project-image"
                    src={project.image}
                    alt={project.title}
                  />
                </div>
              </Link>
              <div className="project-description">
                <div className="projects-titles">
                  <h3>{project.title}</h3>
                  <p className="light-text projects-para">
                    {project.description}
                  </p>
                </div>
                <div className="project-tags">
                  {project.tags.map((tag, tagIndex) => (
                    <span key={tagIndex} className="tag">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="external-projects"></section>
    </div>
  );
};

export default Homepage;
