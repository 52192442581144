const Projects = [
  // {
  //   id: 1,
  //   title: "Tale",
  //   description: "Enabling employees to request salary advances before payday.",
  //   tags: ["APP DESIGN", "WEB APP DESIGN"],
  //   image:
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1730416377/tale-0one_gqgve7.webp",
  //   link: "/projects/quickpay",
  //   about:
  //     "QuickpayNG is a comprehensive platform designed to enhance the financial well-being of employees. It empowers workers to access a portion of their earned wages in real-time, providing flexibility and alleviating the need to wait until payday, especially in times of financial emergencies.",
  //   year: "2023",
  //   industry: "Fintech",
  //   role: ["Product Designer"],
  //   slug: "quickpay",
  //   projectgoals: [
  //     "Provide employees with a secure, easy-to-use application that allows them to access a portion of their earned wages before payday, giving them greater financial flexibility.",
  //     "Create a web platform that allows employers to seamlessly onboard and manage employees, ensuring smooth integration with payroll systems and efficient wage access management.",
  //     "Foster financial wellness by offering employees an alternative to high-interest loans and helping them avoid financial stress",
  //   ],
  //   challenge1:
  //     "We had trouble figuring out how to manage the finances so that employers could easily see how much their employees took and ensure the system properly handled the repayment of those wages.",
  //   challenge2:
  //     "Another challenge we faced was figuring out how to prevent scope creep. Both the mobile app and web app were quite robust, and with the time constraints, it was difficult to ensure we didn’t add unnecessary features that could complicate development and delay timelines.",
  //   solution:
  //     "While working on this project, I conducted UX and market research to gain insights into an unfamiliar sector. This involved examining Nigerian and global companies and identifying market gaps through reviews on platforms like Product Hunt, Google Play, and the App Store. This analysis allowed us to establish a competitive edge. Additionally, I created a design system to ensure consistency across Quickypay’s products.",
  //   solution2:
  //     "To provide employers with access to essential data, I employed data visualization techniques to present information such as Total Wages Advanced, Enrolled Employees, and Total Earned Wages in a digestible format. Given the three-month timeline, I quickly moved into UI design for both the web and hybrid apps. Through team iterations and feedback, I refined the designs to ensure we met our deadline.",
  //   lookingahead: "During this project, I learned how to balance speed with quality to ensure the project goals were met. I also recognized the importance of incorporating user feedback throughout the development process to achieve a strong market fit. Moving forward, future updates will focus on enhancing the employer dashboard, leveraging initial user feedback and usage data to guide improvements.",
  //  lookingahead2:"",
  //   gallery: [
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1727221916/Slide_16_9_-_62_anhhiu.webp",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222052/Slide_16_9_-_61_xxw1df.webp",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222119/Slide_16_9_-_63_ovlscp.webp",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222226/Slidse_16_9_-_65_eq09qg.webp",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222358/Slidse_16_9_-_66_fssjqb.webp",
  //   ],
  //   cta: "VISIT THE WEBSITE →",
  //   website: "https://te.tale.dev/en/merchants",
  // },

  {
    id: 1,
    title: "Quickpay",
    description: "Enabling employees to request salary advances before payday.",
    tags: ["APP DESIGN", "WEB APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1727221735/quiaackpay-one_ydmyi3.webp",
    link: "/projects/quickpay",
    about:
      "QuickpayNG is a comprehensive platform designed to enhance financial well-being for employees. It empowers workers to access a portion of their earned wages in real time, providing flexibility and alleviating the need to wait until payday, especially in times of financial emergencies.",
    year: "2023",
    industry: "Fintech",
    role: ["Product Designer"],
    slug: "quickpay",
    projectgoals: [
      "Provide employees with a secure, easy-to-use application that allows them to access a portion of their earned wages before payday, giving them greater financial flexibility.",
      "Create a web platform that allows employers to seamlessly onboard and manage employees, ensuring smooth integration with payroll systems and efficient wage access management.",
      "Foster financial wellness by offering employees an alternative to high-interest loans and helping them avoid financial stress",
    ],
    challenge1:
      "We had trouble figuring out how to manage the finances so that employers could easily see how much their employees took and ensure the system properly handled the repayment of those wages.",
    challenge2:
      "Another challenge we faced was figuring out how to prevent scope creep. Both the mobile app and web app were quite robust, and with the time constraints, it was difficult to ensure we didn’t add unnecessary features that could complicate development and delay timelines.",
    solution:
      "While working on this project, I conducted UX and market research to gain insights into an unfamiliar sector. This involved examining Nigerian and global companies and identifying market gaps through reviews on platforms like Product Hunt, Google Play, and the App Store. This analysis allowed us to establish a competitive edge. Additionally, I created a design system to ensure consistency across Quickypay’s products.",
    solution2:
      "To provide employers with access to essential data, I employed data visualization techniques to present information such as Total Wages Advanced, Enrolled Employees, and Total Earned Wages in a digestible format. Given the three-month timeline, I quickly moved into UI design for both the web and hybrid apps. Through team iterations and feedback, I refined the designs to ensure we met our deadline.",
    lookingahead: "During this project, I learned how to balance speed with quality to ensure that the project goals were met. I also recognized the importance of incorporating user feedback throughout the development process to achieve a strong market fit. Moving forward, future updates will focus on enhancing the employer dashboard, leveraging initial user feedback and usage data to guide improvements.",
   lookingahead2:"",
    gallery: [
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1727221916/Slide_16_9_-_62_anhhiu.webp", text: 'Snapshot of the QuickpayNG home and insights page which provides employees with a their finances at a glance '},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222052/Slide_16_9_-_61_xxw1df.webp", text: 'Snapshots of the financial screens'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222119/Slide_16_9_-_63_ovlscp.webp", text: 'The employee financial education Flow provides employees with definitions of financial terms they may encounter in the app or while managing their finances, along with categorized articles to help them focus on specific areas they want to learn about.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222226/Slidse_16_9_-_65_eq09qg.webp", text: 'This snapshot illustrates the process of enabling two-factor authentication on the employee management dashboard.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222358/Slidse_16_9_-_66_fssjqb.webp", text: 'Quickpay log in screen'},
    ],
    cta: "VISIT THE WEBSITE →",
    website: "https://quickpayng.com/auth/create-account",
  },
  {
    id: 2,
    title: "Shade UI",
    description: "Free, Flexible Design System for Modern Interfaces.",
    tags: ["DESIGN SYSTEM", "WEB DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1733938831/shaduiport_qc6cag.webp",
    link: "/projects/shadeui",
    about:
      "Shade UI is an open-source design system built to accelerate modern web development. Our mission is to democratize design, making it accessible to users of all backgrounds.",
    year: "2024",
    industry: "UI Kit",
    role: ["Product Designer"],
    slug: "shadeui",
    projectgoals: [
      "Create an accessible component system that helps designers and developers bridge the gap between design and implementation.",
      "Build a community-driven platform where designers and developers can learn and grow together.",
    ],
    challenge1:
      "When developing Shade UI, Tunde and I faced the challenge of determining which components to prioritize and how quickly we could deliver them for version 1.",
    challenge2:
      "",
    solution:
      "We gave select teams early access to the design system to observe how they used it and identify the most valuable components. Based on their feedback, we prioritized those components. To ensure a focused and manageable launch, we started small by releasing a beta version and plan to release new components as we continue to grow.",
    solution2:
      "In addition to collaborating on the design system, I also helped design the Shade UI website, which helps us communicate the design systems benefit to users. ",
    lookingahead: "Shade UI is a labor of love, and I am truly grateful to contribute to a resource for design communities. We look forward to receiving feedback and adding new components which will be detailed in our changelog as we strive to grow Shade UI.",
   lookingahead2:"Working on this project helped me greatly improve my knowledge of design systems, tokens, and documentation. It has also greatly improved my technical knowledge working on the product strategy and developing version 2 of the product.",
    gallery: [
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1735395773/Frame_1707479835_1_trkqpg.webp", text: 'Snapshot of the button components on shade UI'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1735395778/Frame_1707479833_zbi1tg.webp", text: 'Snapshot of a drawer component made with Shade UI'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1735395773/Frame_1707479834_1_thnxnq.webp", text: 'A snapshot of the Shade UI accordions'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1735395777/Frame_1707479836_mmmcyv.webp", text: 'A sample of a sign-up page made with Shade UI'},
    ],
    cta: "USE DESIGN SYSTEM →",
    website: "https://www.figma.com/community/file/1435945000028697090",
  },

  {
    id: 3,
    title: "Admos Hotel",
    description: "Design and development for a hospitality website.",
    tags: ["WEB DESIGN", "WEB DEVELOPMENT"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1729394101/admosbig_cfvnqe.webp",
      link: "/projects/admoshotel",
      about: "Admos Hotel is a home away from home, nestled in the heart of Imota, Lagos. The hotel provides a safe heaven for people and tourists looking to leave behind the hustle and bustle of Lagos.",
    problemstatement:
      "The hotel was looking to increase its visibility and attract more customers with new marketing strategies. Their current methods using traditional advertising has been effective, but they believe they can bring in more people by having a digital presence and increasing their brand visibility. ",
    year: "2023",
    industry: "Hospitality",
    role: ["Product Designer"],
    slug: "admos-hotel",
    projectgoals: [
      "Streamline operations by enabling the hotel to manage bookings and reservations seamlessly, while allowing potential guests to easily browse and book online.",
      "Allow customeres to easily browse and compare different room options, amenities, and rates.",
      "Showcase the hotel to potential guests searching online for accommodation in the area, improve its search result ranking, and attract more traffic.",
    ],
    challenge1:
      "We initially encountered challenges communicating with our target audience due to a language barrier. Many of the customers preferred Yoruba over English, which made it difficult to communicate effectively and gather feedback.",
    challenge2:
      "During the development phase, we also faced significant difficulties with the payment process. Specifically, we struggled to create a seamless payment flow, securely store and retrieve payment data, and connect the database to the backend logic. ",
    solution:
      "We conducted user interviews in both English and Yoruba to gather comprehensive feedback about user needs. After analyzing the local hotel market and creating user personas, we redesigned the navigation to group services under clear categories like Rooms and Events which improved the user experience significantly. ",
    solution2:
      "For the payment system, we integrated Paystack to handle secure transactions and payment data storage, while implementing proper backend validation to ensure reliable processing between the payment gateway and our database",
    workoverview: "I collaborated with Tunde as the lead product designer on this project and also contributed to the front-end development. My role included conducting desk research and user interviews to inform our design decisions. I sketched the initial website designs, which were iterated upon and refined into the final user interface. Additionally, I conducted usability tests that revealed key UX issues. Based on these insights, I made improvements to enhance the overall user experience.",
    workoverview2: "",
    lookingahead: "Overall, this project was a valuable learning experience for both of us. Not only did we have the opportunity to help a business increase its reach and potentially boost its sales, but we also had the chance to work on a project from start to finish. The process of brainstorming and sketching our ideas provided us with a clear roadmap for development.",
   lookingahead2:"Additionally, we learned the importance of speaking the users' language and conducting multiple tests. This allowed us to identify and fix issues that we may have missed otherwise. ",
    gallery: [
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730411849/Frame_1000001145_gicmwo.webp", text: 'We used the Double Diamond design process during this project, beginning with discovering and defining the problem, then moving into ideation and solution development. This approach helped us explore a broad range of ideas before narrowing down to the most effective solutions, ensuring we addressed user needs thoroughly at every stage.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730411848/Frame_1707479808_avup5m.webp", text: 'A snapshot of the questionnaire questions in both English and Yoruba versions. Translating the questionnaire allowed us to bridge initial communication barriers and reach a much wider audience.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730412198/Frame_1707479810_mceaww.webp", text: ''},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1731196671/Frame_17074aassa79829_nboipq.webp", text: 'Outlining the website requirements helped us identify the necessary elements for both the customer and admin sides.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730411847/Frame_1707479809_arelv6.webp", text: 'The websites information architecture was structured similarly to the database model, helping us organize the database efficiently'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730411856/Frame_1000001205_wxlv69.webp", text: 'A collage of the initial drafts, created manually with pen and paper. This approach allowed us to brainstorm freely and make corrections before moving on to the high-fidelity designs.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730412538/Frame_170747981a0_xiqeu6.webp", text: 'LO-FI pages of the about, events, hero section and room navigation pages'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1729392696/admos4_y6xknu.webp", text: 'A HI-FI snapshot of the hero section'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1729392700/admos3_bkxtsx.webp", text: 'About the business'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1729392699/admos1_cwuqd4.webp", text: 'Image gallery'},

    ],
    cta: "",
    website: "",
  },
  {
    id: 4,
    title: "Partner",
    description:
      "Building friendships and community for the elderly",
    tags: ["UX RESEARCH"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1729393155/partner11_1_cekiok.webp",
    link: "/projects/admoshotel",
    about:
      "Loneliness is defined as the feeling of being alone, regardless of social engagement. According to the United Nations' World Population Ageing report from 2015, almost 5% of Nigeria's 200 million people are 60 or older. This figure is predicted to climb to roughly 25.3 million by 2050.Older persons face a variety of challenges as they age, such as the loss of a loved one, separation, or children migrating to larger places. These factors can lead to a lack of social interaction or loneliness among the elderly Partner is a social app to help reduce loneliness and social isolation amongst seniors. ",
    year: "2022",
    industry: "Community",
    role: ["Product Designer"],
    slug: "partner",
    projectgoals: [
      "Identify potential strategies for reducing social isolation and loneliness among the elderly.",
      "Provide a medium for older folks to build friendships and community."
    ],
    challenge1:"Finding elderly individuals willing to openly discuss sensitive topics like loneliness and social isolation proved challenging, as many may feel hesitant to share personal feelings with strangers.",
    challenge2:
      "The research faced several other challenges, including communication barriers among elderly participants who struggled with reading comprehension, necessitating adaptations in questionnaire delivery. Additionally, the focus on specific areas in Lagos limited the diversity of perspectives, making it difficult to capture a comprehensive view of loneliness among the elderly. Cultural sensitivity was crucial to ensure participants felt comfortable discussing their feelings, which added complexity to the interview process. Lastly, some individuals expressed skepticism about the research intent, leading to reluctance in participating or sharing genuine experiences.",
    solution:
      "To make senior citizens feel less lonely as they age, the idea was to build a senior housing that hosts events for them to interact and meet people with similar interests in order to suit the changing demands of the elderly and improve their quality of life. We will provide monthly meet-ups in groups based on their interests at the center to encourage people to socialize.",
    solution2: "I also will design a mobile app to supplement the solution, allowing users to meet new people and learn more about their interests ",
    workoverview: "During my desk research, I came across studies and observations that reinforced the problem statement for this project. For instance, a report by the National Academies of Sciences, Engineering, and Medicine (NASEM) revealed that nearly one-fourth of adults aged 65 and older are socially isolated due to factors like living alone, the loss of family or friends, chronic illness, and hearing loss. Similarly, a study by C.C. Igbokwe et al. (2020) highlighted how elderly individuals in North Central Nigeria experience loneliness due to children relocating to larger cities, a lack of friends, and reduced social interaction. ",
    workoverview2: "To gain firsthand insights into the needs of my potential users, I conducted interviews in three areas of Lagos State—Yaba, Lagos Island, and Festac. For participants who struggled with reading, I prepared a questionnaire and read it aloud to ensure inclusivity. The feedback from these interviews allowed me to construct detailed user personas that captured each participant’s goals, requirements, pain points, and motivations, laying a strong foundation for the design process. Following this, I led brainstorming sessions that helped me refine ideas, create low-fidelity wireframes, and develop a style guide before moving to high-fidelity designs.",
    workoverview3: "As I continued my research, I focused on creating a design accessible for older adults, taking into account specific age-related issues. I learned that color vision deteriorates with age;  a 2014 study showed that 45 percent of people in their mid-70s have color-vision problems in the blue-yellow spectrum, increasing to two-thirds by their mid-90s. To accommodate this, I avoided hues like red and green, which are harder for color-blind users to differentiate, instead opting for blue, a more color-blind-friendly choice. I also considered presbyopia which develops around age 40 as the eye lens begins to harden, making it difficult to read small, close-up text. Therefore, I chose the DM Sans typeface and set an average font size of 16 pixels to ensure readability. This comprehensive approach helped me craft an accessible and empathetic design, keeping the needs of elderly users at the forefront.",
    lookingahead: "This project pushed me outside of my comfort zone and allowed me to deepen my understanding of the challenges older adults face. I learned a lot about designing for senior adults as a result of this project. I realized that there was a lot to consider, such as making the app as simple to use as possible and as similar to apps users are acquainted with or have previously used to avoid losing interest in the app.",
   lookingahead2:"I had to make the design as inclusive as possible for this project, which meant taking into account a range of cognitive issues that affect the elderly, such as memory loss, color blindness, and more. It enhanced my ability to design for accessibility and made me a more empathetic designer, ultimately improving my design skills. This project is yet to be realized; however, I believe it is one with potential, and more research should be conducted regarding this subject.",
    gallery: [
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1731197086/Frame_17aic2074798za13a_go92mt.webp", text: 'Screenshots from the desk research process show Twitter users seeking partnerships for their parents, highlighting the need for this solution.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730413588/Frame_170ssd7479810_vqffb5.webp", text: 'The 5 stage model of design thinking  by Hasso-Plattner Institute of Design at Stanford was used during this process.'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730992973/Frame_17aa074onchw79812_lhwreo.webp", text: 'This quote is from a UCSF study (2002-2008) analyzing data from the Health and Retirement Study, involving 1,604 older adults, which found that social factors play a major role in older adults health.'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730413599/Group_4rkc89_kvxku8.webp", text: 'Using the information gained from each interviewes, we constructed user personas that highlighted their goals, requirements, pain spots, and motivation.'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730413599/Group_525_so8abs.webp", text: ''},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730413624/Frame_17074798za13_tx73ah.webp", text: ''},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730413712/Group_59adcc0_ipeqow.webp", text: 'Empathy map to gain deeper insight into the user experiences, thoughts, and emotions'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1730413604/Travel_Phoaasto_Collage_Desktop_Wallpaper_1_u1ryxu.webp", text: 'LO-FI sketches of the Mobile application'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1729393764/partner1_u8zgos.webp", text: 'User interview questions'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1729393766/partner2_mfqery.webp", text: 'Onboarding pages'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1729393765/partner3_eeuukh.webp", text: 'A Face ID screen was added for easy, stress-free login, along with an Interest Selection screen to personalize group and event recommendations for users.'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1731198542/Frame_1707479829_ldcclw.webp", text: 'Explaination of the homepage'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1729393766/partner4_kjhc2j.webp", text: 'Community pages'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1730413703/Frame_adfv1707479814_g3lg0i.webp",text: ''},
     
    ],
    cta: "",
    website:
      "",
  },
  {
    id: 5,
    title: "Biltapay",
    description:
      " Centralize your finances and get reminders to never miss a payment. ",
    tags: ["WEB DESIGN", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222458/bltapayy1_cv0zzw.webp",
    link: "/projects/biltapay",
    about:
      "Biltapay offers a centralized platform to organize, track, and pay all your bills in one place, making it easy to manage your payments and avoid late fees.",
    year: "2023",
    industry: "Fintech",
    role: ["Product Designer"],
    slug: "biltapay",
    projectgoals: [
      "Create a centralized platform that allows users to organize and manage all their bills in one place for easy access and streamlined management.",
      "Implement features that enable users to schedule their bill payments in advance, helping them avoid late fees and ensuring they stay on top of their financial obligations."
    ],
    challenge1:"During this project, we faced several challenges that prompted us to adapt and find effective solutions. One significant challenge was managing stakeholder expectations, which required us to ensure that our goals aligned with their vision. Additionally, we worked within a tight timeline, necessitating efficient prioritization and streamlined processes.",
    challenge2:
      "Another challenge was navigating aesthetics among the diverse team of designers, which highlighted the need for cohesive collaboration.",
    solution:
      "To address stakeholder expectations, we maintained open lines of communication and regularly updated them on our progress, ensuring everyone was aligned. To manage the tight timeline, we prioritized essential features and implemented agile methodologies, allowing us to adapt quickly to any changes. ",
    solution2:
      "To enhance collaboration among designers, we facilitated brainstorming sessions to harmonize our design vision, resulting in a cohesive aesthetic that integrated our varied perspectives.",
    workoverview: "During this project, we divided the workflows to ensure we met our deadlines. I focused exclusively on the website while contributing to the app by designing key screens, including the onboarding process, identity verification, home page, and bill payment screen. Additionally, I helped establish the visual identity for the app, setting the tone for its overall design.",
    workoverview2: "",
    lookingahead: "This project had its share of twists and turns, but with contributions from the team, we arrived at a direction that worked for everyone. Ultimately, it was successfully launched on both the App Store and web. This experience was highly informative, teaching me to work under time constraints while prioritizing user needs. It also deepened my understanding of the app development process.",
   lookingahead2:"",
    gallery: [
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222764/Slide_16_cjrrsz9_-_67_z0deqt.webp", text: 'Onbaording screens'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222848/Slidiice_16_9_-_70_fndxoe.webp", text: 'A screenshot of the Verify Your Account screen, added to confirm the users identity.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727222949/Slideee_16_9_-_69_erwdng.webp", text: 'Wallet funding process'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727223037/Slideaoc_16_9_-_68_ooqe59.webp", text: ''},
    ],
    cta: "DOWNLOAD THE APP →",
    website: "https://apps.apple.com/us/app/biltapay-bills-payment/id6495485413",
  },
  {
    id: 6,
    title: "Safe Seller",
    description: "One-stop shop to discover and connect with trusted vendors",
    tags: ["UX RESEARCH", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222559/safesellernew_td4ork.webp",
    link: "/projects/safeseller",
    about:
      "Safe Seller is a passion project inspired by my online shopping experiences. To address the challenge of verifying store legitimacy and customer experiences, I developed an app that consolidates my thorough vetting process. Safe Seller allows users to discover vendors by location or category, explore detailed profiles, and read about other users' shopping experiences, making it easier to find trustworthy stores",
    year: "2024",
    industry: "Business Directory",
    role: ["Product Designer"],
    slug: "safe-seller",
    projectgoals: [
      "Put in place a comprehensive vetting process that allows users to verify the legitimacy of online stores, ensuring a safer shopping experience.",
      "Allow users to explore detailed vendor profiles and read reviews from other shoppers, empowering them to make informed purchasing decisions.",
      "Organize a clear user flow to enhance navigation and usability within the app, ensuring a seamless experience for users."
    ],
    challenge1:"While designing the app a significant challenge was structuring the user journey to create an effortless and intuitive experience.",
    challenge2:
      "Another challenge was integrating APIs to enable a comments feature, allowing users to view unfiltered feedback directly from other users. However, the cost associated with implementing this feature posed a challenge, requiring careful consideration of budget constraints while still aiming to enhance the app’s functionality.",
    solution:
      "The solution was to design an algorithm that tailors the app's content based on a user's interests and location. During onboarding, users are prompted to select their preferences, allowing the app to provide personalized recommendations. Over time, as the app learns more about their preferences, it adjusts to offer more relevant suggestions.",
    solution2:
      "I also introduced a Discover section where users can explore local and global vendor activity, read reviews left by other customers, and view top-rated vendors in their area. This feature helps users make informed decisions by ensuring that vendors are properly vetted, reducing the risk of scams. Additionally, users gain access to exclusive deals, such as local Black Friday sales, enhancing their overall shopping experience. The goal is to provide a secure and trustworthy platform for users to shop with confidence.",
    workoverview: "",
    workoverview2: "",
    lookingahead: "I plan to continue working on this project in the future, improving the platform and refining its features. I’d like to focus on making the user experience even smoother, enhancing the vetting process, and integrating more local deals and reviews. My goal is to turn Safe Seller into a reliable go-to platform that helps users easily find trustworthy vendors and make safer shopping decisions.",
   lookingahead2:"",
    gallery: [
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727223241/Slidde_16_9_-_8_twn5rw.webp", text: 'Homepage showcasing the reviews, and categories'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727223294/Slidde_16_9_-_9_eqphku.webp", text: 'Onboarding screen'},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1727223344/Slidde_16_9_-_12_e6zptr.webp", text: 'Community section showcasing the trending, top rated and reviews being made about vendors nearby '},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727223463/Slideb_16_9_-_13_gu2lae.webp", text: ''},
      {image: "https://res.cloudinary.com/dyqg8luud/image/upload/v1727223610/Slside_16_9_-_10_cabih7.webp", text: ''},
    ],
  },
  {
    id: 7,
    title: "British Museum",
    description:
      "A revamped British Museum App for a more immersive learning experience.",
    tags: ["UX RESEARCH", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1727222651/Frame_1000001755_cmgfpb.webp",
    link: "/projects/britishmuseum",
    about:
      "Understanding the present requires insights into the past, and history provides us with that perspective. Museums play a vital role in preserving our history by collecting and showcasing artifacts for the public to learn from. Amongst these museums, the British Museum stands out as an unparalleled custodian of knowledge. Established in 1753, the British Museum houses an impressive collection of over eight million works that document the evolution of human culture from its earliest origins to the present day. As the first public national museum to encompass all fields of knowledge, it provides visitors with a comprehensive view of human achievements across various disciplines. Sparked by my difficulties using the app, I undertook a redesign to potentially improve its user experience.",
    year: "2023",
    industry: "Education",
    role: ["Product Designer"],
    slug: "british-museum",
    projectgoals: [
      "Enhance the visitor experience by offering an intuitive and immersive way to explore the museum’s extensive collection.",
      "Introduce interactive features that engage users, making learning about exhibits more dynamic and enjoyable.",
      "Personalize content and recommendations to create a tailored experience, enriching the overall museum visit."
    ],
    challenge1:"A challenge I faced was ensuring the homepage was more intuitive. The previous design displayed all the tours upfront, but they were all paid, which left users with little to explore for free. This made the homepage feel more like a sales pitch than a place for discovery.",
    challenge2:
      "Other challenges I faced included ensuring that the app still met business objectives after removing the paid tours from the homepage.",
    solution:
      "To make the homepage more organized, I reorganized it by continent, allowing users to navigate collections freely. This not only simplified the navigation but also provided a balance between free content and paid features, ensuring users could explore without feeling pressured to make a purchase.",
    solution2:
      "To address business objectives, I introduced a paywall, balancing the need for revenue with user access to free educational material. I also noticed the museum's artifacts were only available on the website, so I integrated a shopping section within the app, allowing users to purchase items directly after their virtual tours. This not only supports the museum's revenue but also enhances the user experience. Additionally, I added an interactive tour with an audio guide that simulates a personal walkthrough of the museum. I implemented an intuitive image-based search feature, enabling users to find artifacts by uploading pictures. These enhancements make the app more engaging and user-friendly, fostering a deeper connection between users and the museum's collection.",
    workoverview: "",
    workoverview2: "",
    lookingahead: "I learned a lot about advanced prototyping from this project, allowing me to develop my UI and documentation skills. This experience  also taught me the importance of user-centered design in cultural institutions. Moving forward, I’ll apply these lessons to create digital experiences that honor tradition while leveraging technology to enhance accessibility, engagement, and learning.",
   lookingahead2:"",
    gallery: [
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224023/compala_wmztll.webp" , text: ''},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224080/PERSONA_gfuzgg.webp", text: ''},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224148/Slide_16_9_-_41_cbusgj.webp" , text: ''},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224219/Slide_16_9_-_42_sumfx2.webp", text: 'A snapshot showing the image search feature, enabling users to quickly search for artifacts and discover relevant information with ease.'},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224318/Slide_16_9_-_40_wtwkki.webp", text: ''},
      {image:"https://res.cloudinary.com/dyqg8luud/image/upload/v1727224356/Slide_16_9_-_39_ubvgpp.webp", text: ''},
    ],
    cta: "",
    website:
      "",
  },
 
];

export default Projects;
